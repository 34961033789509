

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddPriceTemplateModal from "@/views/apps/research/AddPriceTemplateModal.vue";
import AddPriceReadingModal from "@/components/modals/forms/AddPriceReadingModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddPriceAttributeModal from "@/components/modals/forms/AddPriceAttributeModal.vue";

import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import { Modal } from "bootstrap";
import UploadPriceCollectorModal from "@/components/modals/forms/UploadPriceCollectorModal.vue";
import { status_type, price_tag } from "@/core/data/genericData";
import moment from "moment";

export default defineComponent({
  name: "sales-price-upload-listing",
  components: {
    ExportCustomerModal,
   // AddPriceTemplateModal, 
    //AddPriceReadingModal,
    //AddPriceAttributeModal,
        UploadPriceCollectorModal,
  },
   props: 
  {
      id: { required: true },
  },
  
  // methods:{
  //   onChange(){
  //     console.log('this.formDataValue.startDate')
  //     console.log(this.formDataValue.startDate)
  //     if(!this.formDataValue.startDate){
  //       console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
  //       this.formDataValue.startDate = ''
  //     }
  //   }
  // },
  
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const searchTearm = ref('');
    const price_tag_data = price_tag;
    const viewDataModalRef = ref<null | HTMLElement>(null);
    var formDataViewValue = ref(
      { valuetbl2 : ""});
    var formDataValue = ref(
      { 
        cust_select: "", 
        startDate: "", 
        product_select: "",
        price_select: "",
        }
      );
    const status_list_data = status_type;
    // const product_attr_modal = (price_template_id) => {
    //   const modal = new Modal(document.getElementById("kt_price_modal_attribute"));
    //   modal.show();
    //   (document.querySelector('#price_template') as HTMLInputElement).value = price_template_id;
    // }

    interface Price {
        product_name: string,
        file_name:string,
        collector_upload_id:number,
        price_tag_name:string,
        price_collector_code:string,
        price_collector_name:string,
        upload_time:string,

    }

    // const price_modal = (price_template_id) => {
    //   const modal = new Modal(document.getElementById("kt_price_modal"));
    //   modal.show();
    //   (document.querySelector('#price_template_id') as HTMLInputElement).value = price_template_id;
    // }
    
    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1],
        page_select: 1
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;    
      paginationData.value['page_select'] = 1;  
      getList()
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getList()
       if(paginationData.value['totPage'] == page){
      paginationData.value['page_select'] = page
      }
      if(paginationData.value['activePage'] == 1){
      paginationData.value['page_select'] = page
      }
      
    }

    const product_type_dp = ref([]);
    const setProductdata = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_PRODUCT_PRICE_DATA)
          .then(({ data }) => {
            product_type_dp.value = data;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const price_type = ref([]);
    const setPricedata =async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_PRICE_COLLECTOR_DATA)
          .then(({ data }) => {
            price_type.value = data;
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    }

    // const startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    //     const endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    //     const startDate1 = ref();
    //     startDate1.value = [startDate , endDate]
    //     console.log( startDate1.value[0]) 
    //     formDataValue.value.startDate = startDate1.value
        var tableData = ref<Array<Price>>([]);
      
     const getList = async () => {
       loadingData.value = true;
    //   var start_data = ""
    //     var end_date = ""
    //   if(!formDataValue.value['startDate']){
    //       start_data = ""
    //       end_date = ""
    //   }
    //   else
    //   {
    //     start_data = moment(formDataValue.value.startDate[0]).format("YYYY-MM-DD")
    //     end_date = moment(formDataValue.value.startDate[1]).format("YYYY-MM-DD")
    //   }
    //   console.log( formDataViewValue.value['startDate']) 
      try {

        var values = { 
          "page"  : parseInt(paginationData.value['activePage']) || parseInt(paginationData.value['page_select']), 
          "records_per_page" : parseInt(paginationData.value['perPage']) ,
          "product_id":formDataValue.value.product_select ,
          "price_collector_id":formDataValue.value.price_select ,
          }

        await store.dispatch(Actions.SALES_LIST_PRICE_DATA, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);
          if(data != 0){
          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
          var resultsM = ref<Array<Price>>([])
          
          for (let j = 0; j < data.result_list.length; j++) {

            resultsM.value = Array({
              file_name : data.result_list[j]['file_name'],
              collector_upload_id: data.result_list[j]['collector_upload_id'],
              product_name: data.result_list[j]['product_name'],
              price_tag_name: data.result_list[j]['price_tag_name'],
              price_collector_code: data.result_list[j]['price_collector_code'],
              price_collector_name: data.result_list[j]['price_collector_name'],
              upload_time: data.result_list[j]['upload_time']

            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;
          }
          if(data == 0){
            loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationData.value['total'] = 0;        
          paginationData.value['start'] = 0
          paginationData.value['end'] = 0
          paginationData.value['activePage'] = 1;
          paginationData.value['totPage']  = 1
          paginationData.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          // paginationData.value['page_select'] = 0
          //endpagination
          }
        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    //  }
      
    };

     
      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      

      const initCustomers = ref<Array<Price>>([]);                      
      
      onMounted( async () => {
        await getList()
        
        setCurrentPageBreadcrumbs("Price Collector Details", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].collector_upload_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          refreshData();
          getList();
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };       
      
      interface ViewCategories {
        price_record_id,
        product_id,
        product_name,
        business_name,
        business_city,
        business_district,
        business_state,
        business_pincode,
        business_nature,
        product_type,
        contact_person_name,
        contact_person_no,
        contact_person_email,
        product_brand_name,
        product_base_dimension,
        product_base_grade,
        booking_status,
        price_publish_frequency,
        origin_location,
        incoterm,
        morning_price,
        afternoon_price,
        evening_price,
        price_collection_date,
        remark,
        price_upload_time
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      price_record_id: "",
      product_id: "",
      product_name: "",
      business_name: "",
      business_city: "",
      business_district: "",
      business_state:"",
      business_pincode: "",
      business_nature: "",
      product_type: "",
      contact_person_name: "",
      contact_person_no: "",
      contact_person_email: "",
      product_brand_name: "",
      product_base_dimension: "",
      product_base_grade: "",
      booking_status: "",
      price_publish_frequency: "",
      origin_location: "",
      incoterm: "",
      morning_price: "",
      afternoon_price: "",
      evening_price: "",
      price_collection_date: "",
      remark: "",
      price_upload_time: "",
    }

    
    var tableData2 = ref<Array<ViewCategories>>([]);
   
    return {
      tableData,
      tableData2,  
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,      
      getList,   
      //refresh,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      viewDataModalRef,
      formDataValue,
      formDataViewValue,
      resultsView,
      status_list_data,
      price_tag_data,
      setProductdata,
      product_type_dp,
      loading,
      setPricedata,
      price_type,
    };  

  }  

});
