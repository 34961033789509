
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import JwtService from "@/core/services/JwtService";
//import { status_type, price_tag } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {
    
    },
  
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addCsvModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();
    const router = useRouter(); 
    
    onMounted( async () => {
      //const db_data = {}
     
    });
    

    const formData = ref({
      product_select: "",
      name: "",
      price_select: "",
    });    

    const rules = ref({
      product_select: [
        {
          required: true,
          message: "Product is required",
          trigger: "change",
        },
      ],
      price_select: [
        {
          required: true,
          message: "Price is required",
          trigger: "change",
        },
      ],
    });

    const product_type = ref([]);
    const setProductdata = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_PRODUCT_PRICE_DATA)
          .then(({ data }) => {
            product_type.value = data;
            loading.value = false;
            console.log("DROPDOWN")
            console.log(product_type.value)
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const price_tag_data = ref([]);
    const setPricedata = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.SALES_PRICE_TAG_DATA)
          .then(({ data }) => {
            price_tag_data.value = data;
            loading.value = false;
            
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    let rawdoc = ref() ;
    function encodeDoctoBase64L(element) {
        const fileupload = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(fileupload);
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawdoc.value = {
          file_name:element.target.files[0].name,
          file_data: image_slice,
          }
        }
        else{
          rawdoc.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       };
        
      }

    const UploadImage = async (data) => {
      var user = JSON.parse(JwtService.getToken());
      const db_data = {
        "file_name" : rawdoc.value.file_name,
        "file_data" : rawdoc.value.file_data,
        "product_id": data.product_select,
        "price_tag_id": data.price_select,
        "price_collector_id": user.user_id
        }
        console.log("DBDATA")
        console.log(db_data)
          await store.dispatch(Actions.SALES_UPLOAD_PRICE_DATA, db_data).then(({ is_error , message, status }) => {
           if (is_error == false){

            setTimeout(() => {
              loading.value = false;
              hideModal(addCsvModalRef.value); 
              Swal.fire({
                text: message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                 
                router.go(0)           
              });
            }, 2000)          

          } 
          if(is_error == true) {

            loading.value = false;
            Swal.fire({
              text: message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }

      })
      .catch(({ response }) => {
        console.log(response);        
      });
    }

    const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await UploadImage(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }
      });    
    };


    return {      
      formData,      
      rules,
      submit,
      formRef,
      loading,
      addCsvModalRef,
      encodeDoctoBase64L,
      setProductdata,
      setPricedata,
      rawdoc,
      product_type,
      price_tag_data,
    };
  },
});
